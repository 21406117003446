import React, { useState } from "react";
import NavbarComponent from "src/components/NavbarComponent";
import Footer from "src/components/Footer";
import MyRoute from "src/router/Router";
import { ThreeCircles } from 'react-loader-spinner';

function App() {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  };

  return (
    <>
      {isLoading ? (
        <ThreeCircles
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="three-dots-loading"
          wrapperStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
          wrapperClass=""
        />
      ) : (
        <>
          <NavbarComponent handleClick={handleClick} />
          <MyRoute />
          <Footer />
        </>
      )}
    </>
  );
}

export default App;