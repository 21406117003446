import FlavorIconBBQ from '../assets/images/flavors/FlavorIconBBQ.png';
import FlavorIconCheese from '../assets/images/flavors/FlavorIconCheese.png';
import FlavorIconChiliBBQ from '../assets/images/flavors/FlavorIconChiliBBQ.png';
import FlavorIconSourCream from '../assets/images/flavors/FlavorIconSourCream.png';
import FlavorIconTruffle from '../assets/images/flavors/FlavorIconTruffle.png';

function Flavors() {
    const CKPData = [
        {
            id: 1,
            picture: FlavorIconCheese,
            flavor: "Cheese",
            description: "It's hard not to love the classic cheese flavor—especially at Potato Corner! With the right amount of saltiness and a hint of sweetness, this OG cheese flavor will have you crave for more. Don't say we didn't warn you!"
        },
        {
            id: 2,
            picture: FlavorIconBBQ,
            flavor: "BBQ",
            description: "Here's a barbeque party waiting to happen in your mouth, taste the smoky and rich flavor of the BBQ with every fry! It's definitely an all-time favorite."
        },
        {
            id: 3,
            picture: FlavorIconChiliBBQ,
            flavor: "ChiliBBQ",
            description: "An upgraded version of BBQ with a twist. Enjoy the rich flavors of BBQ with a spicy kick. Spicy lovers, this one's for you. Caution, it can get pretty hot in there!"
        },
        {
            id: 4,
            picture: FlavorIconSourCream,
            flavor: "Sour Cream",
            description: "It's tangy, creamy and oh so addictive! Taste the smooth blend of flavors paired with our fantastic fries. You'll thank us later!"
        },
        {
            id: 5,
            picture: FlavorIconTruffle,
            flavor: "Truffle",
            description: "Indulge in the taste of our premium World Class Truffle flavor. Each bite delivers the rich, aromatic, earthy and umami flavor that you can't get anywhere else. It's perfectly balanced and will surely blow your tastebuds away!​"
        }
    ]

    return (
        <>
            <div className='container'>
                <div className="section-head fw-bold mt-4 border-2 border-bottom">
                    <h2>Flavors</h2>
                </div>
                <div className="row item-product">
                    {CKPData.map((item, index) => (
                        <div className={`card card-${index + 1}`} key={item.id}>
                            <div className="card-header">
                                <img src={`${item.picture}`} alt="Profile Image" className="profile-img" />
                            </div>
                            <div className="card-body">
                                <p className="card-body-content">
                                    {item.flavor}
                                </p>
                                <span className='card-body-description'>
                                    {item.description}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default Flavors