import DocumentTitle from 'src/components/DocumentTitle';

import FlavorIconBBQ from '../assets/images/flavors/FlavorIconBBQ.png';
import FlavorIconCheese from '../assets/images/flavors/FlavorIconCheese.png';
import FlavorIconChiliBBQ from '../assets/images/flavors/FlavorIconChiliBBQ.png';
import FlavorIconSourCream from '../assets/images/flavors/FlavorIconSourCream.png';
import FlavorIconTruffle from '../assets/images/flavors/FlavorIconTruffle.png';

import FFImage1 from '../assets/images/frenchfrie/FFLarge.png';
import FFImage2 from '../assets/images/frenchfrie/FFJumbo.png';
import FFImage3 from '../assets/images/frenchfrie/FFMega.png';
import FFImage4 from '../assets/images/frenchfrie/FFGiga.png';
import FFImage5 from '../assets/images/frenchfrie/FFTera.png';

import CKPImage1 from '../assets/images/chickenpop/CKPLarge.png';
import CKPImage2 from '../assets/images/chickenpop/CKPJumbo.png';
import CKPImage3 from '../assets/images/chickenpop/CKPMega.png';
import CKPImage4 from '../assets/images/chickenpop/CKPGiga.png';
import CKPImage5 from '../assets/images/chickenpop/CKPTera.png';

import CLFImage1 from '../assets/images/curlyfries/PCMYLargeCurlyFries.png';
import CLFImage2 from '../assets/images/curlyfries/PCMYJumboCurlyFries.png';
import CLFImage3 from '../assets/images/curlyfries/PCMYMegaCurlyFries.png';

import WAFFImage1 from '../assets/images/wafflefries/PCMYLargeWaffleFries.png';
import WAFFImage2 from '../assets/images/wafflefries/PCMYJumboWaffleFries.png';
import WAFFImage3 from '../assets/images/wafflefries/PCMYMegaWaffleFries.png';

import XMNImage1 from '../assets/images/xtrameatynuggets/LargeXTRAMeatyNuggets.png';
import XMNImage2 from '../assets/images/xtrameatynuggets/JumboXTRAMeatyNuggets.png';
import XMNImage3 from '../assets/images/xtrameatynuggets/MegaXTRAMeatyNuggets.png';

import BVRImage1 from '../assets/images/beverage/LimeSoda.png';


function Menu() {
    const FFData = [
        {
            id: 1,
            picture: FFImage1,
            description: "Large 1 flavor"
        },
        {
            id: 2,
            picture: FFImage2,
            description: "Jumbo 1 flavor"
        },
        {
            id: 3,
            picture: FFImage3,
            description: "Mega 2 flavors"
        },
        {
            id: 4,
            picture: FFImage4,
            description: "Giga 3 flavors"
        },
        {
            id: 5,
            picture: FFImage5,
            description: "Tera 4 flavors"
        }
    ]

    const CKPData = [
        {
            id: 1,
            picture: CKPImage1,
            description: "Large 1 flavor"
        },
        {
            id: 2,
            picture: CKPImage2,
            description: "Jumbo 1 flavor"
        },
        {
            id: 3,
            picture: CKPImage3,
            description: "Mega 2 flavors"
        },
        {
            id: 4,
            picture: CKPImage4,
            description: "Giga 3 flavors"
        },
        {
            id: 5,
            picture: CKPImage5,
            description: "Tera 4 flavors"
        }
    ]

    const CLFData = [
        {
            id: 1,
            picture: CLFImage1,
            description: "Large 1 flavor"
        },
        {
            id: 2,
            picture: CLFImage2,
            description: "Jumbo 1 flavor"
        },
        {
            id: 3,
            picture: CLFImage3,
            description: "Mega 2 flavors"
        }
    ]

    const WAFFData = [
        {
            id: 1,
            picture: WAFFImage1,
            description: "Large 1 flavor"
        },
        {
            id: 2,
            picture: WAFFImage2,
            description: "Jumbo 1 flavor"
        },
        {
            id: 3,
            picture: WAFFImage3,
            description: "Mega 2 flavors"
        }
    ]

    const XMNData = [
        {
            id: 1,
            picture: XMNImage1,
            description: "Large 1 flavor"
        },
        {
            id: 2,
            picture: XMNImage2,
            description: "Jumbo 1 flavor"
        },
        {
            id: 3,
            picture: XMNImage3,
            description: "Mega 2 flavors"
        }
    ]

    const BVRData = [
        {
            id: 1,
            picture: BVRImage1,
            description: "Lime Soda"
        }
    ]

    const FlavorData = [
        {
            id: 1,
            picture: FlavorIconCheese,
            description: "Cheese"
        },
        {
            id: 2,
            picture: FlavorIconBBQ,
            description: "BBQ"
        },
        {
            id: 3,
            picture: FlavorIconChiliBBQ,
            description: "ChiliBBQ"
        },
        {
            id: 4,
            picture: FlavorIconSourCream,
            description: "Sour Cream"
        },
        {
            id: 5,
            picture: FlavorIconTruffle,
            description: "Truffle"
        }
    ]

    //     { displayImg: Img_menu1, fullImg: Img_menu1_sub },
    //     { displayImg: Img_menu2, fullImg: Img_menu2_sub },
    //     { displayImg: Img_menu3, fullImg: Img_menu3_sub },
    //     { displayImg: Img_menu4, fullImg: Img_menu4_sub },
    //     { displayImg: Img_menu5, fullImg: Img_menu5_sub },
    //     { displayImg: Img_menu6, fullImg: Img_menu6_sub },
    //     { displayImg: Img_menu7, fullImg: Img_menu7_sub },
    //     // Add the rest of your images here
    // ];

    // const [modalImage, setModalImage] = useState('');
    // const modalRef = useRef(null); // Using useRef to access the modal element

    // const openModal = (imageSrc) => {
    //     setModalImage(imageSrc);
    //     const myModal = new window.bootstrap.Modal(modalRef.current);
    //     myModal.show();
    // };

    // useEffect(() => {
    //     const myModalEl = modalRef.current;
    //     myModalEl.addEventListener('hidden.bs.modal', () => {
    //         setModalImage(''); // Reset image source when modal is closed
    //     });

    //     return () => {
    //         myModalEl.removeEventListener('hidden.bs.modal', () => {
    //             setModalImage('');
    //         });
    //     };
    // }, []);
    DocumentTitle("Potato Corner - Menu")
    return (
        <div className="container ">
            <div className="section-head fw-bold mt-4 border-2 border-bottom">
                <h2>Menu</h2>
            </div>
            <div className="topic">
                <p><span style={{ color: 'red' }}>*</span>Flavors</p>
                <div className="item-center pb-3">
                    {FlavorData.map(item => (
                        <div className="menu-content">
                            <div className="image-container-menu" key={item.id}>
                                <img src={`${item.picture}`} className="hover-image" alt="..." />
                                <p className='menu-content-text' style={{ color: 'green' }}>{item.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="topic">
                <p><span style={{ color: 'red' }}>*</span>Fries</p>
                <div className="item-center pb-3">
                    {FFData.map(item => (
                        <div className="menu-content">
                            <div className="image-container-menu" key={item.id}>
                                <img src={`${item.picture}`} className="hover-image" alt="..." />
                                <p className='menu-content-text' style={{ color: 'green' }}>{item.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="topic">
                <p><span style={{ color: 'red' }}>*</span>Super Chicken Pop</p>
                <div className="item-center pb-3">
                    {CKPData.map(item => (
                        <div className="menu-content">
                            <div className="image-container-menu" key={item.id}>
                                <img src={`${item.picture}`} className="hover-image" alt="..." />
                                <p className='menu-content-text' style={{ color: 'orange' }}>{item.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="topic">
                <p><span style={{ color: 'red' }}>*</span>Specialty Fries</p>
                <div className="special-menu flex-size">
                    <div>
                        <p className='topic-menu'>Curly Fries</p>
                        <div className="item-block pb-3">
                            {CLFData.map(item => (
                                <div className="menu-content">
                                    <div className="image-container" key={item.id}>
                                        <img src={`${item.picture}`} className="hover-image" alt="..." />
                                        <p className='menu-content-text' style={{ color: 'orange' }}>{item.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        <p className='topic-menu'>Waffle Fries</p>
                        <div className="item-block pb-3">
                            {WAFFData.map(item => (
                                <div className="menu-content">
                                    <div className="image-container" key={item.id}>
                                        <img src={`${item.picture}`} className="hover-image" alt="..." />
                                        <p className='menu-content-text' style={{ color: 'red' }}>{item.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="topic">
                <p><span style={{ color: 'red' }}>*</span>Xtra Meaty Nuggets</p>
                <div className="item-block flex-size pb-3">
                    {XMNData.map(item => (
                        <div className="menu-content">
                            <div className="image-container" key={item.id}>
                                <img src={`${item.picture}`} className="hover-image" alt="..." />
                                <p className='menu-content-text' style={{ color: 'red' }}>{item.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="topic">
                <p><span style={{ color: 'red' }}>*</span>Beverages</p>
                <div className="item-block pb-3">
                    {BVRData.map(item => (
                        <div className="menu-content">
                            <div className="image-container beverages-item" key={item.id} >
                                <img src={`${item.picture}`} className="hover-image" alt="..." />
                                <p style={{ color: 'green', textAlign:'center', fontSize:'12px', fontWeight:'bold'}}>{item.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Menu