import { Routes, Route } from "react-router-dom";
import Home from "src/pages/Home"
import Location from "src/pages/Location";
import Flavors from "../pages/Flavors";
import WorldBestFlavorFrie from "../pages/WorldBestFlavorFrie";
import SuperChickenPop from "../pages/SuperChickenPop";
import GetInTouch from "../pages/GetInTouch";
import Menu from "../pages/Menu";

const MyRoute = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/flavors" element={<Flavors />} />
        <Route path="/worldbestflavorfrie" element={<WorldBestFlavorFrie />} />
        <Route path="/superchickenpop" element={<SuperChickenPop />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/locations" element={<Location />} />
        <Route path="/getintouch" element={<GetInTouch />} />
      </Routes>
    </>
  );
};

export default MyRoute;